<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <!-- div class="dashboard__container--header pa-0 pt-5">
        <h1>Get Custom Pricing</h1>
      </div> -->
      <div class="dashboard__container--body mt-5 mb-5">
      	<div class="dashboard__container--body--col whiteBack shiftCard" style="margin: auto;">
  <!--         <div class="mb-2">
            <h2 style="border-bottom:1px solid #eee; padding-bottom:0.25rem; margin-bottom:0.5rem;">Business Inquires:</h2>
          </div> -->
          <!-- <p v-if="showSalesForm">Please submit the form below and we will get back to you right away with a quote.</p> -->
          <div class="mb-3"></div>
          <div v-if="!showSalesForm">
            <h3>Thank you for your request.</h3>
            <p>We will be in touch shortly.</p>
          </div>
          <form @submit.prevent>
            <div class="flex justify-space-between align-center mb-2">
              <h2>Q<em>u</em>ote Request</h2>
              <!-- <i class="fas fa-times fa-2x mr-1" @click="$emit('close')" style="cursor: pointer;"></i> -->
            </div>
            <div class="mb-3">
              <p>Our local account manager will be in touch with a customized pricing propsal and to answer any addiotional questions you may have.</p>
            </div>
            <div class="mb-3">
              <label for="firstname">First name</label>
              <input v-model.trim="form.firstname" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
            </div>
            <div class="mb-3">
              <label for="lastname">Last name</label>
              <input v-model.trim="form.lastname" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
            </div>
            <div class="mb-3">
              <label for="phone">Best phone</label>
              <input v-model.trim="form.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
            </div>
            <div class="mb-3">
              <label for="email2">Best email</label>
              <input v-model.trim="form.email" autocomplete="email" type="text" placeholder="you@email.com" id="email2" required minlength="7" />
            </div>
            <div class="mb-3">
              <label for="company">Company you work for</label>
              <input type="text" placeholder="" v-model.trim="form.company" id="company" />
            </div>
            <div class="mb-3">
              <label for="venue">Venue or property (if applicable)</label>
              <input type="text" placeholder="" v-model.trim="form.venue" id="venue" />
            </div>
            <div class="mb-3">
              <label for="location">Your location (city and state)</label>
              <input type="text" placeholder="" v-model.trim="form.location" id="location" />
            </div>
            <div class="mb-5">
              <label for="details">How can we help you?</label>
              <textarea name="details" id="details" cols="30" rows="6" v-model.trim="form.details"></textarea>
            </div>
            <button :disabled="!valid" @click="submit()" class="btn btn__primary btn__large ml-0">
              Submit
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
            <!-- <div class="caption pt-3">
              By clicking 'Sign up', you agree to Jump's <router-link :to="{name: 'terms'}">Terms of Use</router-link> and <router-link :to="{name: 'privacy'}">Privacy Policy</router-link>.
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


        

<script>
import { mapState } from 'vuex'

export default {
  name: 'quote',
  data: () => ({
    options: ['Contractors', 'W-2 Employees Only'],
    performingRequest: false,
    showSalesForm: true,
    form: {},
  }),
  computed: {
    ...mapState(['currentUser']),
    valid() {
      if ((this.form.email && this.form.email.length > 6) && (this.form.firstname && this.form.firstname.length > 0) && (this.form.firstname && this.form.firstname.length > 0) && (this.form.phone && this.form.phone.length == 14) && (this.form.company) && (this.form.location) && (this.form.details)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
    submitForm() {
      this.performingRequest = true
      let form = this.form
      console.log(form)
      let store = this.$store
      store.dispatch('newQuoteRequest', form)
      setTimeout(() => {
        this.performingRequest = false
        this.showSalesForm = false
        form = {}
      }, 1000)
    }
  }
}
</script>